import React from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'

const CookiePolicy = () => {
  return (
    <>
      <Seo
        title={'Cookie Policy | immence'}
        description={
          'With an innovative leading approach, we offer software development services like -Web Design &amp; Development, -Mobile Design &amp; Development, -ERP Consulting &amp; Support, -Corporate Branding and much more. Hurry! Consult your project for free!'
        }
      />
      <Layout>
        <div>
          <section class="cms-heading com-section policySection">
            <div class="container">
              <div class="row">
                <h1 class="hero-heading text-center">Cookie Policy</h1>
                <p class="text-center whiteText">
                  Last Updated: 29th June 2022
                </p>
              </div>
            </div>
          </section>
          <section class="com-section cms-page">
            <div class="container">
              <div class="row">
                <p>
                  The immence website makes use of cookies. By utilizing this
                  website, you accept the cookies used on the website. Below is
                  some basic information about cookies:
                </p>
                <h3 class="blueText">
                  <strong>What does a cookie mean?</strong>
                </h3>
                <p>
                  A cookie works as a minor data component that a website can
                  pass on, to your browser. It will get stored on the hard drive
                  and will be known when you come again to the website. So, when
                  you register online with any of our services and solutions, it
                  shows your positive consensus to receive cookies.
                </p>
                <h3 class="blueText">
                  <strong>Which are the cookies being used?</strong>
                </h3>
                <ul>
                  <li>Google analytics</li>
                </ul>
                <p>
                  Google analytics (GA) is most commonly used to track user
                  communication. This data helps us to know the details about
                  visitors on our website, what they are searching for, which
                  web pages they are focusing on and their entire roadmap to the
                  website. This technology records user information such as your
                  geolocation, device, browser, OS, IP address etc. but none of
                  this information is passed on to us. As a third-party data
                  processor, google analytics utilizes the cookies for further
                  action.
                </p>
                <ul>
                  <li>Website cookies</li>
                </ul>
                <p>
                  Data obtained through cookies and other technical tools is not
                  personal information. But as a part of the privacy policy, we
                  treat this, and data related to IP addresses etc. as personal
                  information only. For e.g., immence makes use of cookies that
                  capture the date of your last visit.
                </p>
                <ul>
                  <li>
                    Is there any way I can evade the usage of cookies on the
                    site?
                  </li>
                </ul>
                <p>
                  When you are visiting the website for the first time, there
                  will be a notification at the bottom that will give you
                  information about our cookies policy. You can still avoid the
                  cookies by disabling them from the browser settings.
                </p>
                <ul>
                  <li>Is there any way I can delete cookies?</li>
                </ul>
                <p>
                  Through browser settings, you can delete cookies. There will
                  be further help available in the browser settings.
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default CookiePolicy
